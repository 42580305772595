// @flow

const TextureSlots = {
    BaseColor : 5,
    MetallicRoughness : 6,
    Normal : 7,
    Occlusion : 8,
    Emission : 9,
    ShadowMap0 : 10
};

export default TextureSlots;
